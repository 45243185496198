.exception {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../public/images/exeption.webp');
  background-size: cover;
  background-color: #100025;
  z-index: 9999;
  padding: 80px 0;

  & img {
    width: 160px;
    height: 40px;
    cursor: pointer;
  }

  &-brand {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &-wrapper {
    width: 100%;
    height: calc(100% - 100px);
    padding: 0 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &-title {
    color: var(--secondary-30, #805FAA);
    text-align: center;
    font-size: 200px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    font-family: 'Muller Extra Bold';

    @include media_1280 {
      font-size: 120px;
    }

    @include media_830 {
      font-size: 80px;
    }

    @include media_530 {
      font-size: 60px;
    }
  }

  &-titleSmall {
    color: var(--secondary-30, #805FAA);
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    font-family: 'Muller Extra Bold';
  }

  &-content {
    &-text {
      color: var(--secondary-30, #805FAA);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;


      @include media_830 {
        font-size: 16px;
      }
    }

    &-button {
      color: var(--neutral-10, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      border-radius: 100px;
      border: 1px solid var(--brand-80, #DE1F1F);
      padding: 10px 24px;
      margin-top: 50px;
      transition: .3s;
      cursor: pointer;

      &:hover {
        background-color: #9F0000;
        border-color: #9F0000;
      }
    }
  }
}