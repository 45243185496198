@import 'styles/variables.scss';
.HeaderDropdown {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: max-content;
  border-radius: 15px;
  box-shadow: 0px 2px 30px 0px rgba(3, 30, 125, 0.05), 0px 2px 20px 0px rgba(3, 24, 98, 0.05);
  background-color: #FFF;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: 0.35s opacity, 0.15s visibility;

  // @include minMediaScreen_770 {
  //   position: absolute;
  //   z-index: 3;
  //   width: 240px;
  //   height: auto;
  //   max-height: calc(100vh - 20px);
  //   top: 35px;
  //   left: 50%;
  //   transform: translate(-50%, 30%);
  // }

  // @include maxMediaScreen_800 {
  //   left: 0;
  //   border-radius: 0px;
  //   z-index: 99;
  // }

  &:before {
    position: absolute;
    z-index: 15;
    content: '';
    width: 390px;
    height: 10px;
    top: -10px;
  }
}

.HeaderDropdownActive {
  visibility: visible;
  opacity: 1;
}

.HeaderDropdownItemsWrapper {
  padding: 20px 0;
}