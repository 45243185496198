@import 'styles/variables.scss';
.MainInfoBlockHeader {
  position: fixed;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(100deg, #000 32.31%, #333 99.67%);
  justify-content: space-between;
  padding: 10px 50px;
  z-index: 99;

  @include media_830 {
    padding: 10px 20px;
  }
}

.MainInfoBlockHeaderLinksWrapper {
  display: flex;

  @include media_830 {
    display: none;
  }
}

.MobileMenu {
  display: none;

  @include media_830 {
    display: initial;
  }
}

.HeaderLogo {
  text-decoration: none;
  color: #FFF;
  // @include textBoldFont18;
}

.MainInfoBlockHeaderMenu {
  display: flex;
  align-items: center;
}

.MainInfoBlockHeaderCounty {
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #FFF;
  padding: 10px 18px 10px 20px;
  cursor: pointer;
  margin-left: 30px;

  span {
    color: #E1E2EF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 6px;
  }
}

.MainInfoBlockHeaderCountryIcon {
  path {
    stroke: #E1E2EF;
  }
}

.MainInfoBlockHeaderIcon {
  margin-left: 4px;

  path {
    stroke: #FFF;
  }
}

.MainInfoBlockHeaderProfile {
  margin-left: 30px;
  cursor: pointer;

  path {
    transition: .3s;
  }

  &:hover {
    path {
      stroke: #DE1F1F;
    }
  }
}

.MainInfoBlockHeaderSearch {
  cursor: pointer;

  path {
    transition: .3s;
  }

  &:hover {
    path {
      stroke: #DE1F1F;
    }
  }
}

.MainInfoBlockHeaderLink {
  position: relative;
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #DE1F1F;
    height: 1px;
    text-align: left;
    opacity: 0;
    transition: all .5s;
  }

  &:hover:after {
    width: 100%;
    animation: fill 1s forwards;
    -webkit-animation: fill 1s forwards;
    -moz-animation: fill 1s forwards;
    opacity: 1;
    z-index: -10;
  }

  &:not(:last-child) {
    margin-right: 30px;
  }

  @include media_830 {
    width: max-content;
    padding: 10px 0;
  }
}

.MainInfoBlockHeaderLinkActive {
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    content: '.';
    color: transparent;
    background: #DE1F1F;
    height: 1px;
    text-align: left;
    opacity: 1;
    transition: all .5s;
  }
}

.HeaderDropdownItem {
  padding: 12px 30px;
  color: var(--secondary-80, #13002C);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  transition: .25s;

  &:hover {
    background: #333;
    color: #FFFFFF
  }
}

.MobileMenuDrop {
  display: none;
  transform: translateX(900px);
  position: fixed;
  top:79px;
  left: 0;
  color: #FFF;
  padding: 20px;
  width: 100%;
  background: linear-gradient(100deg, #000 32.31%, #333 99.67%);
  transition: .5s;
  z-index: 99;

  @include media_830 {
    display: initial;
  }
}

.MobileMenuDropActive {
  transform: translateY(0px);
}