@import 'styles/variables.scss';
@import 'variables.scss';
@import 'exception.scss';

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: auto !important;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #2A1544;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #472F66;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #2A1544;
  }
}

* {
  box-sizing: border-box;
  font-family: 'Google Sans';
  font-display: swap;
}

.iframe {
  background-color: transparent !important;
}

.iframe .iframe-active {
  border-color: #ac9286;
}

.iframe .iframe-active:hover {
  background-color: #ac9286;
}

.iframe .iframe-disabled {
  background-color: #ac9286;
  opacity: 0.3;
}

.iframe-modal {
  background-color: rgb(238, 238, 238) !important;
  color: #ac9286 !important;
}

.MuiBackdrop-root {
  background-color: transparent !important;
}

.app_main {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #100025;

  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.category {
  position: relative;
}

.category:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: red;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.category:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.ellipsis-text {
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
  max-height: 4.5em;
  margin-right: -1em;
  padding-right: 1em;
}

.ellipsis-text-shown {
  max-height: none;
  height: auto;
  overflow: visible;
  line-height: 1.5em;
}

.show-more-link {
  display: none;
}

button {
  outline: none !important;
}

iframe {
  filter: invert(1);
  border-radius: 10px;
}

.CircularProgressbar {
  width: 77px !important;
  height: 77px !important;

  @include media_830 {
    width: 57px !important;
    height: 57px !important;
  }
}

.MuiInputLabel-outlined {
  transform: translate(14px, 25px) scale(1) !important;
  transition: color 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.go_top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 65px;
  position: fixed;
  bottom: 100px;
  right: 20px;
  border-radius: 60px;
  background-color: #9F0000;
  z-index: 99999;
  transition: .4s;
  transform: rotateZ(-90deg);
  cursor: pointer;

  &:hover {
    background-color: #DE1F1F;
  }
}

.admissions-pagination {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.admissions-pagination__item {
  transition: all 0.3s ease;

  cursor: pointer;

  width: 40px;
  height: 40px;
  background-color: #2A1544;
  border-radius: 4px;
  outline: none;
  border: none;

  margin-right: 10px;
}

.admissions-pagination__item:last-child {
  margin-right: 0;
}

.admissions-pagination__item_selected {
  background-color: #1A0633;
  color: white;
}

.admissions-pagination-splitter {
  display: inline-flex;

  width: 40px;
  height: 40px;
  margin-right: 10px;

  align-items: center;
  justify-content: center;
}

.image-gallery-image {
  max-height: 606px !important;
  width: 100% !important;
  object-fit: cover !important;
  margin-right: 50px !important;
}

.image-gallery-image,
.image-gallery-thumbnail-image {
  border-radius: 10px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: 2px solid #805FAA !important;
  border-radius: 11px;
}

.ButtonMarginRight {
  margin-right: 10px;
}

.ButtonMarginLeft {
  margin-left: 10px;

  @include media_830 {
    margin-left: 0;
    margin-top: 10px;
  }
}

.Loader {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.react-select__control {
  width: 100%;
  padding: 4px 6px !important;
  background: #fff !important;
}

.react-select__single-value {
  color: #000 !important;
}

.react-select__control--menu-is-open,
.react-select__control--is-focused {
  border-color: #797D93 !important;
  box-shadow: none !important;
}

jdiv {
  z-index: 9999 !important;
}